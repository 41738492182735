// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiRoot: 'http://localhost:3001',
  // apiRoot: 'https://api-v2-dev.evento.sa',
  apiRoot: 'https://api.evento.sa',
  sportApiRoot: 'https://sport-api.evento.sa',
  authRoot: 'https://auth.evento.sa',
  apiRootAdmin: 'https://api.evento.sa/admin',
  defaultLanguage: 'ar',
  arTitle: 'Evento | ايفينتو ',
  MERCHANT_ID: '3000000011', // DEV
  MERCHANT_JS:
    'https://ksa.gateway.mastercard.com/checkout/version/56/checkout.js',
  enTitle: 'Evento | ايفينتو',
  paymentRoot: 'https://pay.evento.sa/payment',
  workspaceKey: '072e53ac-2aa6-4e96-85fe-f5e9d7686d9c',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
